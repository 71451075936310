<template>
  <div class="kContainer">
    <i :class="this.newClass"></i>
    <div class="kInfo">
      <span class="kSpan">{{ this.data.leg }}</span>
      <h5 class="kStats">{{ this.data.data }}</h5>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

export default {
  name: 'Info',
  props: {
    data: {
      default: null,
      required: true
    }
  },
  data () {
    return {
      newClass: ''
    }
  },
  mounted () {
    this.newClass = 'kIcon ft-' + this.data.icon
  },
  methods: {
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    }
  }
}
</script>

<style>
  .kContainer {
    margin: 7px 0px;
    display: inline-block;
    width: 50%;
  }
  .kIcon {
    display: inline-block;
    font-size: 40px;
    margin-right: 5px;
    color: #aaa;
  }
  .kInfo {
    display: inline-block;
  }
  .kSpan {
    color: #aaa;
  }
  .kStats {
    font-size: 20px;
    font-weight: 600;
  }
</style>
