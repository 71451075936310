<template>
  <div class="col-12 col-sm-12 col-md-4 kCard">
    <div class="kSubCard">
      <h5 class="kTitle kUnderTitle">{{this.data.title}}</h5>
      <info v-for="(unit, index) in this.data.info" :key="`div_${index}`" :data="unit" />
    </div>
  </div>
</template>

<script>
import Info from '@/components/Kpi/Info.vue'

export default {
  name: 'WrapInfo',
  props: {
    classRoot: {
      type: String,
      default: 'card card-fullheight col-12'
    },
    data: {
      default: null,
      required: false
    }
  },
  components: {
    Info
  }
}
</script>

<style>
  .kCard {
    background:#fff;
  }
  .kSubCard {
    padding: 14px;
    background:#F3F3F3;
  }
  .kUnderTitle {
    margin-bottom: 15px;
  }
</style>
