<template>
  <div class="kAll col-12">
    <h5 class="kTitle">{{this.title}}</h5>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'WrapStatus',
  props: {
    classRoot: {
      type: String,
      default: 'card card-fullheight col-12'
    },
    title: {
      type: String,
      default: null,
      required: true
    }
  }
}
</script>

<style>
  .kAll{
    margin-top: 20px;
  }

  .kTitle {
    color: #877953;
    font-size: 18px;
    font-weight: 600;
  }
</style>
