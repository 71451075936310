<template>
  <div :class="classRoot" style="background:white">
    <h5 class="kSubTitle">{{this.subtitle}}</h5>
    <div class="row">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardContainer',
  props: {
    classRoot: {
      type: String,
      default: 'card card-fullheight col-12 kCard'
    },
    subtitle: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>

<style>
  .kSubTitle {
    color: #3838386E;
    font-weight: 600;
    padding-bottom: 5px;
  }
  .kCard {
    padding-top: 14px;
  }
</style>
